@import "src/styles/colors";

.button {
    outline: none !important;
    cursor: pointer !important;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-wrap: nowrap;
    width: fit-content;
}

.link {
    text-decoration: none !important;
}

.capitalize {
    text-transform: capitalize !important;
}

.uppercase {
    text-transform: uppercase !important;
}

.noPadding {
    padding: 0 !important;
}

.disabled {
    opacity: 0.5 !important;
    pointer-events: none;
}

.before {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.strong {
    font-weight: 700;
}
@import "src/styles/colors";

.row {
    display: flex;
    flex-direction: row;
    width: fit-content;
    max-width: 100%;
    position: relative;
}

.padding0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.padding4 {
    padding-top: 4px;
    padding-bottom: 4px;
}

.padding8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.padding12 {
    padding-top: 12px;
    padding-bottom: 12px;
}

.padding16 {
    padding-top: 16px;
    padding-bottom: 16px;
}

.padding24 {
    padding-top: 24px;
    padding-bottom: 24px;
}

.padding32 {
    padding-top: 32px;
    padding-bottom: 32px;
}

.spacing0 {
    padding-left: 0px;
    padding-right: 0px;
}

.spacing4 {
    padding-left: 4px;
    padding-right: 4px;
}

.spacing8 {
    padding-left: 8px;
    padding-right: 8px;
}

.spacing12 {
    padding-left: 12px;
    padding-right: 12px;
}

.spacing16 {
    padding-left: 16px;
    padding-right: 16px;
}

.spacing24 {
    padding-left: 24px;
    padding-right: 24px;
}

.spacing32 {
    padding-left: 32px;
    padding-right: 32px;
}

.gap0 {
    gap: 0px;
}

.gap4 {
    gap: 4px;
}

.gap8 {
    gap: 8px;
}

.gap10 {
    gap: 10px;
}

.gap12 {
    gap: 12px;
}

.gap16 {
    gap: 16px;
}

.gap24 {
    gap: 24px;
}

.gap32 {
    gap: 32px;
}

.stretched {
    width: 100%;
    min-width: 0px;
}

.wrap {
    flex-wrap: wrap;
}

.center {
    align-items: center;
}

.start {
    align-items: flex-start;
}

.end {
    align-items: flex-end;
}

.stretch {
    align-items: stretch;
}

.noOverflow {
    overflow: hidden;
}

.justifyCenter {
    justify-content: center;
}

.justifyStart {
    justify-content: flex-start;
}

.justifyEnd {
    justify-content: flex-end;
}

.justifyBetween {
    justify-content: space-between;
}

.justifyAround {
    justify-content: space-around;
}

.scrollable {
    overflow-y: auto;
}

.clickable {
    cursor: pointer;
}

.paddingTop {
    padding-top: 12px;
}

.flex {
    flex: 1;
}
@import "src/styles/colors";

.panel {
  flex: 1;
  border-radius: 8px;

  display: flex;

  max-width: 100%;
}

.padding0 {
  padding: 0px;
}

.padding12 {
  padding: 12px;
}

.padding16 {
  padding: 16px;
}

.padding24 {
  padding: 24px;
}

.padding32 {
  padding: 32px;
}

.gap0 {
  gap: 0px;
}

.gap4 {
  gap: 4px;
}

.gap8 {
  gap: 8px;
}

.gap12 {
  gap: 12px;
}

.gap16 {
  gap: 16px;
}

.gap24 {
  gap: 24px;
}

.gap32 {
  gap: 32px;
}

.primary {
  background: $white;
  border: 1px solid $gray-400;
  box-shadow: 0px 6px 20px 0px #333E490F;
}

.secondary {
  background: $gray-100;
}

.stretched {
  width: 100%;
}

.red {
  background: $red-light;
}

.borderRadius4 {
  border-radius: 4px;
}

.borderRadius8 {
  border-radius: 8px;
}
@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.dialogFooter {
  border: none !important;
  border-radius: 0 0 8px 8px !important;
  padding: 0 !important;
  margin-top: 24px !important;
  display: flex !important;
  justify-content: flex-end !important;

  @include media-breakpoint-down("md") {
    padding-bottom: 80px !important;
  }
}